import React from "react"
import Layout from '../components/Layout/Layout';

class Error extends React.Component {
  render() {
    return (
      <Layout>
        <h1>404</h1>
      </Layout>
    )
  }
}

export default Error;